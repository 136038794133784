<template>
  <v-autocomplete
    :items="searchForm ? searchLocalities : localities"
    :outlined="searchForm"
    :prepend-icon="searchForm ? showPrependIcon ? 'mdi-map' : '' : ''"
    :label="$vuetify.lang.t('$vuetify.account.address_dialog.locality')"
    :loading="loading"
    :placeholder="$vuetify.lang.t('$vuetify.account.address_dialog.start_typing')"
    :search-input.sync="search"
    item-text="name"
    required
    v-model="locality"
    clearable
    no-filter
    :disabled="disabled"
    hide-no-data
    :hide-details="searchForm"
    return-object
    @change="updateFormField"
    tabindex="2"
  />
</template>

<script>
  import {debounce} from 'lodash'
  import {mapState} from 'vuex'
  import {HTTPError} from '@/lib/http'
  import {ACCOUNT_LOCALITY_PRELOAD, ACCOUNT_LOCALITY_SEARCH} from '@/store/modules/account/mutation-types';
  import {SEARCH_ACCOUNT_LOCALITY_PRELOAD, SEARCH_ACCOUNT_LOCALITY} from '@/store/modules/searchAccount/mutation-types';

  const QUERY_MIN_LENGTH = 3;
  const SEARCH_DEBOUNCE_TIME = 400;

  export default {
    name: 'LocalityAutocomplete',
    data() {
      return {
        search: null,
        loading: false,
        locality: null
      }
    },
    props: {
      schema: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        default: true
      },
      searchForm: {
        type: Boolean,
        default: false
      },
      showPrependIcon: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      makeSearch: debounce(function (schema, query) {
        if (this.loading) return;

        this.loading = true;

        // API call
        try {
          if (this.searchForm) {
            return this.$store.dispatch(SEARCH_ACCOUNT_LOCALITY, {schema, query});
          } else {
            return this.$store.dispatch(ACCOUNT_LOCALITY_SEARCH, {schema, query});
          }
        } catch (error) {
          if (!(error instanceof HTTPError)) this.$snackbar(error.message, 'error');
        } finally {
          this.loading = false;
        }
      }, SEARCH_DEBOUNCE_TIME),
      preloadData(schema) {
        if (this.loading) return;

        this.loading = true;

        // API call
        try {
          if (this.searchForm) {
            return this.$store.dispatch(SEARCH_ACCOUNT_LOCALITY_PRELOAD, {schema});
          } else {
            return this.$store.dispatch(ACCOUNT_LOCALITY_PRELOAD, {schema});
          }
        } catch (error) {
          if (!(error instanceof HTTPError)) this.$snackbar(error.message, 'error');
        } finally {
          this.loading = false;
        }
      },
      // Move to AddressDialog ?
      updateFormField(locality) {
        this.$emit('change', locality);
      }
    },
    computed: {
      ...mapState({
        localities: state => state.account.localities,
        searchLocalities: state => state.searchAccount.searchLocalities
      })
    },
    watch: {
      search(query) {
        if (this.schema
          && query
          && query.length >= QUERY_MIN_LENGTH
          && !(this.locality && this.locality.name === query)
        ) this.makeSearch(this.schema, query);
      },
      schema(schema) {
        this.preloadData(schema);
      }
    }
  }
</script>
